import React, { useEffect, useState } from 'react'
import Matt from "../../images/profiles/MattWarren.jpg";
import Rodney from "../../images/profiles/RodneyAscher.jpg";
import Larissa from "../../images/profiles/LarissaBeck.jpg";
import Josh from "../../images/profiles/JoshLong.jpg";
import Aaron from "../../images/profiles/AaronNelson.jpg";
import Matthew from "../../images/profiles/MatthewRogers.jpg";
import Ryan from "../../images/profiles/RyanSelf.jpg";
import Kara from "../../images/profiles/KaraSullivan.jpg";
import "./Team.scss";
const Team = props => {

  const [compLoaded, setCompLoaded] = useState(false);

  useEffect(() => { setCompLoaded(true) }, []);
  useEffect(() => {
    if (compLoaded) props.loaded('Team');
  }, [compLoaded, props]);

  const Team = [
    {
      id: 'matt',
      name: 'Matt Warren',
      role: 'Writer / Director',
      description: <p>Matt Warren is a Utah-raised, LA-based writer, director, and producer with 10+ years pro 
        experience creating narrative video content for the web. He has made numerous scripted and unscripted
        shorts and web series, and has worked as a screenwriter, film critic, entertainment journalist, humorist,
        blogger, editor, graphic designer, and videographer. Detail-oriented and well organized despite maintaining
        his offbeat sense of humor and profound love of discomforting weirdness, Matt has collaborated on web 
        projects with talent including Michael Showalter, Matt Selman, Eric Kaplan, David Wain, Judah Friedlander,
        the Sklar Bros., Aubrey Plaza and more. He is currently the Senior Manager of Digital Content at Film 
        Independent. DELICATE ARCH is his feature film debut as writer/director</p>,
      image: Matt,
    },
    {
      id: 'rodney',
      name: 'Rodney Ascher ',
      role: 'Executive Producer',
      description: <><p>Rodney Ascher is a still-alive filmmaker best known for exploring the spaces between documentary,
        genre, experimental, essay and archival films. His work has screened at Sundance, Cannes’ Director’s Fortnight,
        the BFI and around the world. Notable projects include his first feature, ROOM 237 - an obsessive look at the 
        Shining through the eyes of five very different people, THE NIGHTMARE which was called "The Scariest Movie of 
        the decade” by Birth.Movies.Death (though that might be overstating it), and Primal Screen, a pilot for a 
        documentary series about cathode-based traumas for the Shudder network. His most recent film A GLITCH IN THE 
        MATRIX uses multiple styles of 3D animation to illustrate the experiences and philosophies of people who suspect 
        the world itself is not quite real.</p>
        </>,
      image: Rodney,
    },
    {
      id: 'larissa',
      name: 'Larissa Beck',
      role: 'Producer',
      description: <p>Larissa is a Salt Lake City based producer with over a decade of experience in the film 
        industry. She has produced a number of feature length films, commercials, and new media content. In 2017, 
        she founded Lars in Jars Productions, a U.S. based company that specializes in production services. She 
        has created content for NBC Universal, Disney, Bose, Coca Cola, Tax ACT, and various other brands. Her 
        films include THE NAMELESS DAYS (premiered April 1st, 2022), THE SEEDING (in post-production), BEHIND 
        YOU (2017), THE APPEARANCE (2018), and PAINTED (2022). In 2022, Larissa co-founded Sunset City Films, a 
        Cape Town based production company specializing in education and content creation</p>,
      image: Larissa,
    },
    {
      id: 'josh',
      name: 'Josh Long',
      role: 'Producer / 1st AD',
      description: <p>Josh Long is a filmmaker based in Los Angeles, where he’s worked as a 1st Assistant Director 
        for over a decade. In addition to his work as an assistant director, he is the screenwriter of the films 
        MAN CAMP and SOME OTHER GIRL. When he’s not making movies, Josh enjoys music, learning new languages, 
        literature, and Ultimate Frisbee</p>,
      image: Josh,
    },
    {
      id: 'aaron',
      name: 'Aaron Nelson',
      role: 'Producer / Locations',
      description: <p>Aaron Nelson is a filmmaker from Salt Lake City, UT with 18 years in the entertainment 
        industry. He has acquired experience in every department while learning all the ins and outs of film 
        production, while going from intern to production assistant, to 2nd assistant director, to department 
        head as location manager on multi-million dollar projects. A family man, he and his wife Molly have 3 
        children together; Gus, Oliver and Hugo. Aside from his passion for movies and filmmaking, he also 
        loves Basketball, Dungeons &amp; Dragons, and Comedy</p>,
      image: Aaron,
    },
    {
      id: 'matthew',
      name: 'Matthew Rogers',
      role: 'Director of Photography',
      description: <p>Matthew Rogers is a Tennessee native, but recent Utah transplant, who’s career has covered a
        wide variety of projects and talent-from features films to midget wrestling (yes, really), Dolly Parton to
        Ludacris. He enjoys variety in projects and life, always with sarcasm mixed in. He’s been married to his 
        wife Kelly for nearly 20 years and they have two sets of twin daughters.</p>,
      image: Matthew,
    },
    {
      id: 'ryan',
      name: 'Ryan Self',
      role: 'Co-Producer',
      description: <p>Ryan Self is a writer and producer in Los Angeles, currently working in Development at
        Warner Bros TV. He proudly wrote on the final two seasons of The Tom & Jerry Show (Cartoon Network)
        and worked on Season 2 of The Flight Attendant (HBO Max). He also loves improv, frisbee, and board games...
        like... a lot. Probably too much.</p>,
      image: Ryan,
    },
    {
      id: 'kara',
      name: 'Kara Sullivan',
      role: 'Casting Director',
      description: <p>Kara Sullivan Casting, established in 2003, is an independent casting director. With a 
        diverse range of clients from all over the world and an award-winning pedigree, KSC casts features, 
        short films, commercials, new media projects, and voice over work. Some of her many film credits 
        include Cuck with Sally Kirkland, SIx Feet with Raymond Cruz and Robert Palmer Watkins, Free Lunch 
        Express with Eric Roberts and Kevin Sorbo, and Immigrant starring Harry Hamlin.  KSC's webseries 
        credits include multiple shorts for CryptTV, Award winning BET+ series The Rich and the Ruthless 
        starring Richard Brooks and Victoria Rowell, Love Work and Other Demons, coming soonThe Potwins with 
        Barry Bostwick plus other legendary talent and Romancing the Joan starring the legendary Joan Rivers.
        In addition to her work as a professional casting director, Kara is privileged to work with the 
        University of Southern California's prestigious School of Cinematic Arts as a casting advisor. She 
        returned as casting professor for the USC/ShanghaiTech Directing Master Class, an exclusive 
        professional program for a group of elite directors from China.</p>,
      image: Kara,
    },
  ]
  return (<div id={props.id}>
    <div id="team-component">
      <div className="bg-overlay">
        <div className="page-container">
          <h1 className="color-primary mt-1em mb-1em upper">Our Team</h1>
          {Team && Team.length && <div className="members">
            {Team.map(({id, name, role, description, image}, i) => <div key={`member_image_${id}`} className={`member ${id}`}>
              <img id={`${id}-bio-img`} src={image} alt={`${name} member portrait`} title={`Member Portrait ${name}`} align="left" />
              <h2 className="member-name upper">{name}</h2>
              <h5 className="member-role upper italic lighter color-primary">{role}</h5>
              <div className="member-description">{description}</div>
            </div>)}
          </div>}
        </div>
      </div>
    </div>
  </div>)
}

export default Team