import React, { useEffect } from 'react'
import AumletsImg from "../../images/contributors/Bio_Amulets.jpg";
import MelodyGunImg from "../../images/contributors/Bio_MelodyGun.jpg";
import './Sound.scss'
import AudioFile from '../../resources/audio.mp3'
import { useState } from 'react';

const Sound = React.forwardRef((props, ref) => {

  const [compLoaded, setCompLoaded] = useState(false);

  useEffect(() => { setCompLoaded(true) }, []);
  useEffect(() => {
    if (compLoaded) props.loaded('Sound');
  }, [compLoaded, props]);

  const Contributors = [
    {
      id: 'amulets',
      artist: 'Amulets',
      role: 'Composer',
      description: <p>Amulets is the solo project of Portland-based audio + visual artist Randall Taylor. 
        Amulets employs handmade cassette tape loops and live processed guitar loops to 
        create live, lush soundscapes and immersive drones. Through the recontextualisation 
        of cassettes, sampling, field recording, and looping, these long-form compositions blur 
        the genres of ambient, drone, noise, and electronic music.</p>,
      image: AumletsImg,
      links: [
        {label: 'Website', url: 'http://www.amuletsmusic.com/'},
        {label: 'Demo 1', url: 'https://vimeo.com/731408330/5028d8cbac'},
        {label: 'Demo 2', url: 'https://vimeo.com/731411730/402bc3e9f9'},
        {label: 'Demo 3', url: 'https://vimeo.com/731412618/57cbc4ba6e'},
      ],
    },
    {
      id: 'melodygun-studios',
      artist: 'MelodyGun Studios',
      role: 'Sound Designer, Editor, Mixer',
      description: <><p>Thomas Ouziel and Hamed Hokamzadeh founded MelodyGun in 2014 based on a 
        passion for impactful storytelling, collaboration and a relentless eye for detail.</p>
        <p>Located in Hollywood, MelodyGun Sound Studios is an independent studio specializing 
        in narrative sound editorial and mix with over 100 films to their name. You may 
        recognize their work in films like Arctic (Mads Mikkelsen), In Dubious Battle (Selena 
        Gomez, James Franco), Test Pattern (nominated Best First Feature @ Spirit Awards) 
        and The Good Catholic (Danny Glover, John C. McGinley)</p></>,
      image: MelodyGunImg,
      links: [
        {label: 'Website', url: 'https://www.melodygun.com/'},
        {label: 'IMDB', url: 'https://pro.imdb.com/company/co0547147?s=ccc10b88-77f5-9ce6-2ba1-f58b03482a1e&site_preference=normal'},
      ],
    }
  ]
  return (<div id={props.id}>
    <div id="sound-component">
      <div className="bg-overlay">
        <div className="page-container">
          <h1 className="color-primary mt-1em mb-1em upper center">Sound</h1>
          <div className="container">
            <div className="audio-player-container">
              <audio controls ref={ref}>
                <source src={AudioFile} type="audio/mpeg"></source>
              </audio>
            </div>
            {Contributors && Contributors.length && Contributors.map(({id, artist, role, description, image, links}, i) => (<div key={`contributor_node_${id}`} className="contributor">
              <h2 className='artist'>{artist}</h2>
              <div className="bio-img">
                <img id={`${id}-bio-img`} src={image} alt={`${artist} ${role}`} title={`${role}: ${artist}`} />
              </div>
              <h5 className='role role-sm italic lighter color-primary'>{role}</h5>
              <h3 className='role role-lg italic lighter color-primary'>{role}</h3>
              <article className='description'>
                {description}
                {links && links.length && <div className="links">
                  {links.map(({label, url}, l_i) => <React.Fragment key={`${id}_link_${l_i}`}>
                    {l_i > 0 && <span className='bullet'>•</span>}
                    <a className="d-ib" target="_blank" rel="noreferrer" href={url}>{label}</a>
                  </React.Fragment>)}
                </div>}
              </article>
            </div>))}
          </div>
        </div>
      </div>
    </div>
  </div>)
})

export default Sound