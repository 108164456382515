import React, { useEffect, useState } from 'react'
import Delicate from "../../images/pages/home/Delicate.png";
import Arch from "../../images/pages/home/Arch.png";
import './Home.scss';
import Globals from '../../globals.json'

const Home = props => {

  const [compLoaded, setCompLoaded] = useState(false);

  useEffect(() => { setCompLoaded(true) }, []);
  useEffect(() => {
    if (compLoaded) props.loaded('Home');
  }, [compLoaded, props]);

  return (<div id={props.id}>
    <div id="home-component">
      <div className="logo-container">
        <div className="logo-image delicate">
          <img src={Delicate} alt="" title={Globals.Site.Title} />
        </div>
        <div className="logo-image arch">
          <img src={Arch} alt="" title={Globals.Site.Title} />
        </div>
      </div>
    </div>
  </div>)
}

export default Home