import React, { useEffect, useState } from 'react'
import './Fans.scss'
import Poster from './Poster';

const Fans = props => {

  const [posters, setPosters] = useState();
  const [compLoaded, setCompLoaded] = useState(false);

  useEffect(() => {
    let posterImages = Array.from({length: 20}, (_, i) => ((i+1)<10) ? `poster0${i+1}.jpg` : `poster${i+1}.jpg`);
    setPosters(posterImages);
    setCompLoaded(true);
  }, [])
  useEffect(() => {
    if (compLoaded) props.loaded('Fans');
  }, [compLoaded, props]);

  return (<div id={props.id}>
    <div id="fans-component">
      <div className="page-container">
        <h1 className="h-red upper">Delicate Arch</h1>
        <span className="is-for upper">Is For Fans Of...</span>
        <div className="posters-container">
          {posters && posters.length && posters.map((poster, i) => <Poster key={`fan_poster_${poster}`} src={poster} isOdd={i % 2 === 0} />)}
        </div>
      </div>
    </div>
  </div>)
}

export default Fans