import React, { useEffect, useState } from 'react'
import SB05 from "../../images/storyboards/SB-05.jpg";
import SB06 from "../../images/storyboards/SB-06.jpg";
import SB07 from "../../images/storyboards/SB-07.jpg";
import './FilmingStyle.scss'

const FilmingStyle = props => {

  const [compLoaded, setCompLoaded] = useState(false);

  useEffect(() => { setCompLoaded(true) }, []);
  useEffect(() => {
    if (compLoaded) props.loaded('FilmingStyle');
  }, [compLoaded, props]);

  return (<div id={props.id}>
    <div id="filming-style-component">
      <div className="page-container">
        <h1 className="primary mt-1em mb-1em upper">Filming Style</h1>
        <div className="subtitle">The action of DELICATE ARCH occurs across three film formats + aspect ratios... <b>Alpha</b>, <b>Beta</b> and <b>Zeta</b>.</div>
      </div>
      <div className="reality-container">
        <div className="reality reality-1">
          <h2 className="before-label upper">Alpha Reality</h2>
          <div className="sb-img"><img src={SB05} alt="Storyboard 05" title="Storyboard 05" /></div>
          <h2 className="after-label upper">Alpha Reality</h2>
          <ul>
            <li><p className="no-pb">2.35:1</p></li>
            <li><p className="no-pb">Gritty but cinematic</p></li>
            <li><p className="no-pb">Hi-def video (ARRI, RED) or</p></li>
            <li><p className="no-pb">Super-16 mm</p></li>
            <li><p className="no-pb">Modern “Prestige Horror” look and feel</p></li>
          </ul>
        </div>
        <div className="reality reality-2">
          <h2 className="before-label upper">Beta Reality</h2>
          <div className="sb-img"><img src={SB06} alt="Storyboard 06" title="Storyboard 06" /></div>
          <h2 className="after-label upper">Beta Reality</h2>
          <ul>
            <li><p className="no-pb">4:3</p></li>
            <li><p className="no-pb">Raw, vintage, smeary</p></li>
            <li><p className="no-pb">Local news broadcast grade</p></li>
            <li><p className="no-pb">1970s-80s “Bad Touch” look and feel</p></li>
          </ul>
        </div>
        <div className="reality reality-3">
          <h2 className="before-label upper">Zeta Reality</h2>
          <div className="sb-img"><img src={SB07} alt="Storyboard 07" title="Storyboard 07" /></div>
          <h2 className="after-label upper">Zeta Reality</h2>
          <ul>
            <li><p className="no-pb">9:16</p></li>
            <li><p className="no-pb">Crisp, bright, digital</p></li>
            <li><p className="no-pb">Modern smart phone mediated by AR filters</p></li>
            <li><p className="no-pb">“Tik Tok” look and fee</p></li>
          </ul>
        </div>
      </div>
    </div>
  </div>)
}

export default FilmingStyle