import React from 'react'

const Downloadable = props => {

  const getfile = (e) => {
    e.preventDefault();
    const link = document.createElement('a')
    link.setAttribute('href', props.file);
    link.setAttribute('download', props.file);
    link.click();
    link.remove();
  }

  return (<a href={props.file} onClick={(e) => getfile(e)} >{props.children}</a>)
}

export default Downloadable