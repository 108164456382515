import React, { useEffect, useState } from 'react'
import './Synopsis.scss'

const Synopsis = props => {
  const [compLoaded, setCompLoaded] = useState(false);

  useEffect(() => { setCompLoaded(true) }, []);
  useEffect(() => {
    if (compLoaded) props.loaded('Synopsis');
  }, [compLoaded, props]);

  return (<div id={props.id}>
    <div id="synopsis-component">
      <div className="bg-overlay">
        <div className="page-container">
          <div className="synopsis">
            <h1 className="h-red mt-1em mb-sh upper">Logline</h1>
            <p>Four college kids with fracturing relationships take a camping trip to Southern Utah in order to escape an ecological disaster up 
            North.</p>
            <p>Alone in the desert, they begin to suspect that their reality might not be as it seems and soon realize they’re being watched.</p>
            <p>Let’s see what they do.</p>
          </div>
          <div className="synopsis">
            <h1 className="h-red mt-1em mb-sh upper">Synopsis</h1>
            <p>Grant, Wilda, Cody, and Ferg are all college students in Salt Lake City. Grant and Cody are supposed to be best friends, but Grant—a 
            paranoid and spoiled film student—suspects that Cody is secretly hooking up with Wilda, an ex-girlfriend Grant is struggleing to 
            remain amicable with. Their friend is Ferg, Wilda’s nonbinary stoner cousin and an amateur student of Theosophy and Ritual Majick.</p>
            <p>Fleeing an atmospheric disaster known as “The Inversion” in the Salt Lake Valley, the group decides to go camping in Southern Utah’s 
            Arches National Park.</p>
            <p>As Grant’s obsessive voyeurism escalates on-site, he begins to sense a malevolent, yet imperceptible, force encroaching on them. 
            What is it? An ancient Lovecraftian Evil? An extraterrestrial manipulation? A nuclear experiment gone wrong? Fucking zombies?!?!</p>
            <p>Or… something even weirder?</p>
          </div>
        </div>
      </div>
    </div>
  </div>)
}

export default Synopsis