import React, { useEffect, useState } from 'react'
import CodyPhoto from "../../images/chars/chars_cody.jpg";
import FergPhoto from "../../images/chars/chars_ferg.jpg";
import GrantPhoto from "../../images/chars/chars_grant.jpg";
import WildaPhoto from "../../images/chars/chars_wilda.jpg";
import "./Characters.scss";
const Characters = props => {

  const [compLoaded, setCompLoaded] = useState(false);

  useEffect(() => { setCompLoaded(true) }, []);
  useEffect(() => {
    if (compLoaded) props.loaded('Characters');
  }, [compLoaded, props]);

  const Characters = [
    {
      id: 'grant',
      name: 'Grant',
      age: '21',
      description: `Hipster nerd, favors collared shirts and Clark Kent glasses. Indoor kid. A film student who fancies himself 
        the next Quentin Tarantino (or at least Rian Johnson). Tightly wound, his mind is always working too far 
        forward, laterally, or inward. Never in the here and now.`,
      image: GrantPhoto,
    },
    {
      id: 'cody',
      name: 'Cody',
      age: '21',
      description: `Outdoorsy and tan. Considers Indiana Jones a realistic aspirational figure. Can be bullying and brusque, 
        but cares about ecology and his friends, probably in that order. Alpha male energy with a whiff of 
        wilderness guide about him.`,
      image: CodyPhoto,
    },
    {
      id: 'wilda',
      name: 'Wilda',
      age: '21',
      description: `Messy-cute, hails from a lower middle-class family, attending college on a volleyball scholarship. She’s 
        blond, cool, and talks a lot of shit. Is obsessed by influencer culture but isn’t sure if she wants to make fun 
        of them or be one herself.`,
      image: WildaPhoto,
    },
    {
      id: 'ferg',
      name: 'Ferg',
      age: '19',
      description: `Puppyish, they/them nonbinary skewing masc. A tattooed community college townie. An amateur 
        Theosophy scholar and would-be practitioner of ritual magic, if only they could find the time. A stoner-philosopher
        type way ahead on the stoner part.`,
      image: FergPhoto,
    },
  ]
  return (<div id={props.id}>
    <div id="characters-component">
      <div className="bg-overlay">
        <div className="page-container">
          <h1 className="mt-1em mb-1em color-primary upper">Characters</h1>
          {Characters && Characters.length && <div className="characters">
            {Characters.map(({id, name, age, description, image}, i) => <div key={`character_image_${id}`} className="character">
              <div className="character-img">
                <img id={`${id}-bio-img`} src={image} alt={`${name} character portrait`} title={`Character Portrait ${name}`} />
              </div>
              <div className="character-data">
                <h2 className="character-name upper"><span className="color-primary">{name}</span> (<span className="lighter">{age}</span>)</h2>
                <p className="character-description">{description}</p>
              </div>
            </div>)}
          </div>}
        </div>
      </div>
    </div>
  </div>)
}

export default Characters