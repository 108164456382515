import React, { useState } from 'react'
import { Characters, DirectorStatement, Fans, FilmingStyle, Footer, Home, LocationComp, Synopsis, Sound, Team, Themes, Navs } from '../../components'
import useImagePreloader from '../../components/Common/Hooks'
import Transitional from '../../components/Common/Transitional'
import TheArch2 from '../../images/transitions/transition01.jpg'
import Hoodoos from '../../images/transitions/transition02.jpg'
import OldCar from '../../images/transitions/transition03.jpg'
import SandyRoom from '../../images/transitions/transition04.jpg'
import Teens from '../../images/transitions/transition05.jpg'
import Tent from '../../images/transitions/transition06.jpg'
import TheArch from '../../images/transitions/transition07.jpg'

const preloadSrcList = [ TheArch2, Hoodoos, OldCar, SandyRoom, Teens, Tent, TheArch ]

const HomePage = () => {
  const { imagesPreloaded } = useImagePreloader(preloadSrcList)
  const [loadStatus, setLoadStatus] = useState({});
  // const [imagesPreloaded, setImagesPreloaded] = useState(null);
  const audioPlayerRef = React.createRef();

  // useEffect(() => {
  //   if (loadStatus.footer) {
  //     const { imagesPreloaded } = useImagePreloader(preloadSrcList);
  //     setImagesPreloaded(imagesPreloaded);
  //   }
  // }, [loadStatus]);

  const loadNextComp = (justLoaded) => {
    // console.log('a', justLoaded);
    if (!loadStatus[justLoaded]) {
      // console.warn('B', justLoaded);
      setLoadStatus({...loadStatus, ...{[justLoaded]: true}});
    }
  }

  return (<>
    <Navs player={audioPlayerRef} loaded={loadNextComp} />
    {loadStatus.Navs && <Home id="home" loaded={loadNextComp} />}
    {loadStatus.Home && <Synopsis id="synopsis" loaded={loadNextComp} />}
    {loadStatus.Footer && imagesPreloaded && <Transitional image={TheArch2} />}
    {loadStatus.Synopsis && <DirectorStatement id="directors-statement" loaded={loadNextComp} />}
    {loadStatus.Footer && imagesPreloaded && <Transitional image={Teens} />}
    {loadStatus.DirectorStatement && <Themes id="themes" loaded={loadNextComp} />}
    {loadStatus.Footer && imagesPreloaded && <Transitional image={TheArch} />}
    {loadStatus.Themes && <Fans id="for-fans" loaded={loadNextComp} />}
    {loadStatus.Fans && <LocationComp id="location" loaded={loadNextComp} />}
    {loadStatus.Footer && imagesPreloaded && <Transitional image={Hoodoos} />}
    {loadStatus.LocationComp && <Sound id="sound" loaded={loadNextComp} ref={audioPlayerRef} />}
    {loadStatus.Footer && imagesPreloaded && <Transitional image={SandyRoom} />}
    {loadStatus.Sound && <FilmingStyle id="filming-style" loaded={loadNextComp} />}
    {loadStatus.Footer && imagesPreloaded && <Transitional image={OldCar} />}
    {loadStatus.FilmingStyle && <Characters id="characters" loaded={loadNextComp} />}
    {loadStatus.Footer && imagesPreloaded && <Transitional image={Tent} />}
    {loadStatus.Characters && <Team id="team" loaded={loadNextComp} />}
    {loadStatus.Team && <Footer id="contact" loaded={loadNextComp} />}
  </>)
}

export default HomePage