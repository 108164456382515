import React, { useEffect, useState } from 'react'
import DelicateArchImage from "../../images/pages/location/DelicateArch.jpg";
import './Location.scss'

const LocationComp = props => {

  const [compLoaded, setCompLoaded] = useState(false);

  useEffect(() => { setCompLoaded(true) }, []);
  useEffect(() => {
    if (compLoaded) props.loaded('LocationComp');
  }, [compLoaded, props]);

  return (<div id={props.id}>
    <div id="location-component">
      <div className="page-container">
        <h1 className="h-dark-red d-ib mt-pt2em mb-pt2em upper">
          Location
          <span className="h-red ml-3">Moab, UT</span>
        </h1>
        <h1 className="h-red mb-sh mt-pt2em mb-pt2em upper lighter">Filmed November, 2022</h1>
      </div>
      <div className="arch-img">
        <img src={DelicateArchImage} alt="" title="Delicate Arch Film" loading="lazy" />
      </div>
    </div>
  </div>)
}

export default LocationComp