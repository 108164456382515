import React, { useEffect, useState } from 'react'
import './Footer.scss'
import { FaInstagram, FaTwitter } from 'react-icons/fa'
import { SiFacebook, SiGmail } from 'react-icons/si'
import { BiArrowToTop } from 'react-icons/bi'
import { FiCheckCircle, FiShare2 } from 'react-icons/fi'
import { AiOutlineBulb } from 'react-icons/ai'
import { Link } from 'react-scroll'
import BuzPlanFile from '../../resources/docs/DelicateArch_BusinessPlan_FINAL_09.29.22.pdf'
import Downloadable from '../Common/Downloadable'
import Globals from '../../globals.json'

const Footer = props => {

  const [compLoaded, setCompLoaded] = useState(false);

  useEffect(() => { setCompLoaded(true) }, []);
  useEffect(() => {
    if (compLoaded) props.loaded('Footer');
  }, [compLoaded, props]);

  const handleSharing = (e) => {
    e.preventDefault();
    if (navigator.share) {
      navigator.share({
        title: `${Globals.Site.Title}, Official Website`,
        text: '',
        url: Globals.URLs.SiteHomeUrl,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  }

  return (<div id={props.id}>
    <div id="contact-component">
      <div className="bg-overlay">
        <div className="vertical-sorter">
          <div className="main-container">
            <div className="col contacts">
              <h1 className="mt-1em mb-pt5em upper">Contact Us</h1>
              <div className="footer-items">
                <div className="footer-item social-media-item instagram">
                  <a href="https://www.instagram.com/mpmwarren/" target="_blank" rel="noreferrer">
                    <FaInstagram /> @delicatearchpic
                  </a>
                </div>
                <div className="footer-item social-media-item twitter">
                  <a href="https://twitter.com/delicatearchpic" target="_blank" rel="noreferrer">
                    <FaTwitter /> @delicatearchpic
                  </a>
                </div>
                <div className="footer-item social-media-item email">
                  <a href="mailto:delicatearchfilm@gmail.com" target="_blank" rel="noreferrer">
                    <SiGmail /> delicatearchfilm@gmail.com
                  </a>
                </div>
                <div className="footer-item social-media-item facebook">
                  <a href="https://www.facebook.com/DelicateArchMovieProject" target="_blank" rel="noreferrer">
                    <SiFacebook /> Delicate Arch on Facebook
                  </a>
                </div>
              </div>
            </div>
            <div className="col resources">
              <h1 className="mt-1em mb-pt5em upper">Resources</h1>
              <div className="footer-items">
                <div className="footer-item resource-item seed-and-spark">
                  <a href={Globals.URLs.supportSnS} className="navbar-item" target="_blank" rel="noreferrer">Seed&Spark <span className='greenlit'><AiOutlineBulb />Green Light!</span></a>
                </div>
                {navigator.share && <div className="footer-item resource-item sharing pointer">
                  <a onClick={(e) => handleSharing(e)}>Spread the Word <FiShare2 /></a>
                </div>}
                <div className="footer-item resource-item business-plan">
                  <Downloadable file={BuzPlanFile}>Download Business Plan <FiCheckCircle /></Downloadable>
                </div>
                <div className="footer-item resource-item website">
                  <Link className='pointer' to="home" spy={true} smooth={'easeInOutExpo'} duration={700} offset={-80} >
                    <span className="ml3">Return to Top </span><BiArrowToTop />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="spacer-container"></div>
          <div className="sub-container">
            <div className="footer-text color-gray">
              <p className="no-pb">
                <a href="https://twitter.com/dandarger" target="_blank" rel="noreferrer" className="color-white">@DANOS</a> snapped and this site existed.
              </p>
              <div>
                <p className="no-pb">&copy;{new Date().getFullYear()} MATT WARREN | DELICATE ARCH, LLC</p>
              </div>
              <div className="vcc">
                <div id="logo-btn" className="circular-btn mr-3"></div>
                <p className="no-pb">All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>)
}

export default Footer