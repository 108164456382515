import React, { useEffect, useState } from 'react'
import MattHeadshot from "../../images/profiles/MattWarren_headshot.jpeg";
import SB01 from "../../images/storyboards/SB-01.jpg";
import SB02 from "../../images/storyboards/SB-02.jpg";
import SB03 from "../../images/storyboards/SB-03.jpg";
import SB04 from "../../images/storyboards/SB-04.jpg";
import "./DirectorStatement.scss";

const DirectorStatement = props => {
  const [compLoaded, setCompLoaded] = useState(false);

  useEffect(() => { setCompLoaded(true) }, []);
  useEffect(() => {
    if (compLoaded) props.loaded('DirectorStatement');
  }, [compLoaded, props]);

  return (<div id={props.id}>
    <div id="directors-statement-component">
      <div className="bg-overlay">
        <div className="page-container">
          <div className="layout-container">
            <h1 className='mt-1em mb-sh upper color-white'>Director’s Statement</h1>
            <article className="item statement lighter">
              <p>What if there was a horror movie whose conceit was that the audience watching it was, in real time, 
              the monster menacing its characters? Or the unseen ghost haunting its protagonists? Or even the 
              masked slasher, deadly shark, alien invaders, et cetera?</p>
              <p>Most importantly: what if this idea was as literal as possible? Many thrillers flirt with these themes of 
              audience implication, though it is often turned into metaphor, abstracted, or obscured.</p>
              <p>But not DELICATE ARCH.</p>
              <p>If successful, DELICATE ARCH will utilize this concept to deliver a terrifying, wholly original, 
              commercial, and entertaining horror movie to satisfy critics and genre fans alike. And if it is very 
              successful, it will rupture the very fabric of reality itself, driving its viewers mad in ancient, cosmic ways.</p>
              <p>DELICATE ARCH is a film designed to be puzzled over, feared, and enjoyed.</p>
              <p>Using elements of autobiography turbocharged with a laundry list of pet obsessions ranging 
              from Phillip K. Dick to Theosophy, Ritual Majick, Vintage Technology, Augmented Reality, Found 
              Footage, Neo-Psychedelia and more, our hope is to create a textured, playful, and visually maximalist 
              experience that will be impossible to ignore… or forget.</p>
            </article>
            <div className="item profile-pic">
              <div className="img">
                <img id="matt-head" src={MattHeadshot} alt="Matt Warren Headshot" title="Matt Warren" />
                <div className="right attribution">Matt Warren</div>
              </div>
            </div>
            <div className="item stats lighter">
              <div><p className="no-pb"><b>Category:</b><span>Feature film</span></p></div>
              <div><p className="no-pb"><b>Genre:</b><span>Psychological Horror</span></p></div>
              <div><p className="no-pb"><b>Expected run time:</b><span>90 min.</span></p></div>
              <div><p className="no-pb"><b>Budget:</b><span>$200-500k</span></p></div>
              <div><p className="no-pb"><b>Filmed:</b><span>November, 2022</span></p></div>
              <div><p className="no-pb"><b>State:</b><span>Post Production</span></p></div>
            </div>
          </div>
          <div className="item story-boards">
            <div className="sb-img"><img src={SB01} alt="Storyboard 01" title="Storyboard 01" /></div>
            <div className="sb-img"><img src={SB02} alt="Storyboard 02" title="Storyboard 02" /></div>
            <div className="sb-img"><img src={SB03} alt="Storyboard 03" title="Storyboard 03" /></div>
            <div className="sb-img"><img src={SB04} alt="Storyboard 04" title="Storyboard 04" /></div>
            <div className="attribution">Storyboard art by Nathan Heigert</div>
          </div>
        </div>
      </div>
    </div>
  </div>)
}

export default DirectorStatement