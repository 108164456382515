import React, { useEffect, useState } from 'react'
import './Navs.scss'
import { BiMenu, BiVolumeFull } from 'react-icons/bi'
import { BsPauseFill } from 'react-icons/bs'
import { MdClose } from 'react-icons/md'
import { Link } from 'react-scroll'
import { TbHeartHandshake } from 'react-icons/tb'
import Globals from '../../globals.json'
import { useDebouncer, useInterval } from '../Common/Hooks'
import { Direction } from '../../enums.ts'

const Navs = props => {
  // const topTrayWHRation = 0.75;
  const delayedPopperActionDelayDefault = 1000;
  const [delayedPopperActionDelay, setDelayedPopperActionDelay] = useState(null);
  const [suppressScrollResponseDelay, setSuppressScrollResponseDelay] = useState(null);
  const [fullMenuOpen, setFullMenuOpen] = useState(false)
  const [audioPlayer, setAudioPlayer] = useState(null)
  const [isAudioPaying, setIsAudioPaying] = useState(false)
  const [compLoaded, setCompLoaded] = useState(false);
  const [scrollData, setScrollData] = useState({sTop: 0, direction: Direction.DOWN});
  const [scroll, setScroll] = useState(false);
  const [popperHidden, setPopperHidden] = useState(false);
  const [suppressScrollResponse, setSuppressScrollResponse] = useState(false);
  const debouncedScroll = useDebouncer(scroll, 50);
  const debouncedPopperHidden = useDebouncer(popperHidden, 300);

  useEffect(() => {
    document.addEventListener("scroll", e => {
      setScroll({
        sTop: document.documentElement.scrollTop,
        sHeight: document.documentElement.scrollHeight,
        wHeight: window.innerHeight,
        wWidth: window.innerWidth,
        event: e});
    }, {passive:true});
    setCompLoaded(true);
    return () => document.removeEventListener("scroll", ()=>{});
  }, []);

  useEffect(() => {
    if (props.player) {
      setAudioPlayer(props.player.current);
    }
    if (compLoaded) props.loaded('Navs');
  }, [compLoaded, props]);

  // Debounced Scroll Handler
  useEffect(() => {
    // console.log('debouncedScroll', debouncedScroll);
    if (debouncedScroll && debouncedScroll.top !== null) {
      const direction = (debouncedScroll.sTop > scrollData.sTop) ? Direction.DOWN : Direction.UP
      setScrollData({
        sTop: debouncedScroll.sTop,
        sHeight: debouncedScroll.sHeight,
        wHeight: debouncedScroll.wHeight,
        wWidth: debouncedScroll.wWidth,
        direction: direction,
        event: debouncedScroll.event
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedScroll]);

  useEffect(() => {
    if (!suppressScrollResponse) {
      // console.log('scrollData', scrollData);
      if (fullMenuOpen && popperHidden) {
        setPopperHidden(false);
      } else if (scrollData.direction === Direction.UP) {
        // if (scrollData.sTop > (scrollData.wWidth * topTrayWHRation) && popperHidden) {
        //   setPopperHidden(false);
        // }
        setPopperHidden(false);
      } else if (scrollData.direction === Direction.DOWN) {
        setPopperHidden(true);
      }
      setDelayedPopperActionDelay(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollData]);

  useEffect(() => {
    if (audioPlayer) {
      audioPlayer.addEventListener('pause', () => {
        // console.log('pause')
        setIsAudioPaying(false);
      })
      audioPlayer.addEventListener('play', () => {
        // console.log('play')
        setIsAudioPaying(true);
      })
    }
    return () => {
      if (audioPlayer) {
        audioPlayer.removeEventListener('pause', () => {});
        audioPlayer.removeEventListener('play', () => {});
      }
    }
  },[audioPlayer]);

  const toggleAudio = () => {
    if (audioPlayer) {
      if (isAudioPaying) audioPlayer.pause();
      else audioPlayer.play();
    }
  }

  const toggleFullMenu = () => {
    if (fullMenuOpen)
      document.documentElement.classList.remove('suppress-events')
    else
      document.documentElement.classList.add('suppress-events')
    setFullMenuOpen(!fullMenuOpen)
  }

  const handleFullMenuLinkClick = () => {
    setSuppressScrollResponse(true);
    toggleFullMenu();
    setPopperHidden(true);
    setSuppressScrollResponseDelay(1500);
  }

  useInterval(() => {
    setDelayedPopperActionDelay(null);
    if (!popperHidden) setPopperHidden(true);
  }, delayedPopperActionDelay);

  useInterval(() => {
    setSuppressScrollResponseDelay(null);
    setSuppressScrollResponse(false);
  }, suppressScrollResponseDelay);

  const handlePopperOpenerEvent = (type) => {
    setDelayedPopperActionDelay(null);
    // console.log(type);
    if (type === 'enter' && popperHidden) {
      setPopperHidden(false);
    }
    if (type === 'leave') {
      setDelayedPopperActionDelay(delayedPopperActionDelayDefault);
    }
  }
  
  const getMenuLinks = (haveClose = false) => {
    const MenuLinks = [
      {"id":"synopsis","label":"Synopsis"},
      {"id":"directors-statement","label":"Director's Statement"},
      {"id":"themes","label":"Themes"},
      {"id":"for-fans","label":"Fans"},
      {"id":"location","label":"Location"},
      {"id":"sound","label":"Sound"},
      {"id":"filming-style","label":"Filming Style"},
      // {"id":"mood","label":"Mood"},
      {"id":"characters","label":"Characters"},
      {"id":"team","label":"Team"},
      {"id":"contact","label":"Contact"},
    ];
    return (<>
      {MenuLinks && MenuLinks.length && MenuLinks.map(({id, label}) => {
        return <Link key={`menu_item_${id}`} className={`navbar-item ${id}${haveClose ? ' upper':''}`} activeClass="active" to={id} spy={true}
            smooth={'easeInOutExpo'} duration={Globals.Settings.ScrollDurationMS} offset={haveClose ? 0 : -80} onClick={haveClose ? handleFullMenuLinkClick : ()=>{}}>
          {label}
        </Link>
      })}
    </>)
  }

  return (<div id="Navs">
    {fullMenuOpen && <div id="main-menu">
      <Link className={`navbar-item`} activeClass="active" to="home" spy={true} smooth={'easeInOutExpo'} 
          duration={Globals.Settings.ScrollDurationMS} offset={0} onClick={toggleFullMenu}>
        HOME
      </Link>
      {getMenuLinks(true)}
    </div>}
    {fullMenuOpen && <div id="overlay"></div>}

    <div id="top-nav-bar">
      <Link className={`navbar-item`} activeClass="active" to="home" spy={true} smooth={'easeInOutExpo'} 
          duration={Globals.Settings.ScrollDurationMS} offset={-80}>
        <div id="logo-btn" className="circular-btn"></div>
      </Link>
      {getMenuLinks()}
      {/* <a href={Globals.URLs.supportSnS} className="navbar-item" target="_blank" rel="noreferrer">Support</a> */}
    </div>

    <div className={`popper-opener${fullMenuOpen ? ' full-menu-open' : ''}`} onMouseEnter={e => handlePopperOpenerEvent('enter')} onMouseLeave={e => handlePopperOpenerEvent('leave')}></div>
    <div className={`popper-tray${fullMenuOpen ? ' open' : ''}${debouncedPopperHidden ? ' hide' : ''}`}>
      <div id="menu-popper" className={`popper circular-btn${fullMenuOpen ? ' open' : ''}`} onClick={toggleFullMenu}>
        {!fullMenuOpen && <BiMenu />}
        {fullMenuOpen && <MdClose />}
      </div>
      <div id="audio-popper" className={`popper circular-btn${fullMenuOpen ? ' open' : ''}`} onClick={toggleAudio}>
        {isAudioPaying && <BsPauseFill />}
        {!isAudioPaying && <BiVolumeFull />}
      </div>
    </div>
  </div>)
}

export default Navs