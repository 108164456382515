import React from 'react'
// import PropTypes from 'prop-types'

const Poster = props => {
  return (
    <div className={`poster ${props.isOdd ? 'odd' : 'even'}`}>
      <img src={require(`../../images/posters/${props.src}`)} alt={`Fans also like ${props.src}`} />
    </div>
  )
}

export default Poster