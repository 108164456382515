import React, { useEffect, useState } from 'react'
import Theme1 from "../../images/pages/themes/TentGirl.jpg";
import Theme2 from "../../images/pages/themes/Binoculars.jpg";
import Theme3 from "../../images/pages/themes/Realities.jpg";
import Theme4 from "../../images/pages/themes/Audiences.jpg";
import "./Themes.scss";
const Themes = props => {

  const [compLoaded, setCompLoaded] = useState(false);

  useEffect(() => { setCompLoaded(true) }, []);
  useEffect(() => {
    if (compLoaded) props.loaded('Themes');
  }, [compLoaded, props]);

  const ThemeMeta = {
    mov: 'Movies',
    voy: 'Voyeurism',
    frac: 'Fracturing realities',
    aud: 'Audience complicity',
  }
  return (<div id={props.id}>
    <div id="themes-component">
      <div className="bg-overlay">
        <div className="page-container">
          <h1 className="mt-1em mb-1em color-white upper center">Themes</h1>
          <div className="container">
            <div className="theme-item theme-1-img">
              <img src={Theme1} alt={ThemeMeta.mov} title={ThemeMeta.mov} />
              <h2 className="lighter upper">{ThemeMeta.mov}</h2>
            </div>
            <div className="theme-item theme-2-img">
              <img src={Theme2} alt={ThemeMeta.voy} title={ThemeMeta.voy} />
              <h2 className="lighter upper">{ThemeMeta.voy}</h2>
            </div>
            <div className="theme-item theme-3-img">
              <img src={Theme3} alt={ThemeMeta.frac} title={ThemeMeta.frac} />
              <h2 className="lighter upper">{ThemeMeta.frac}</h2>
            </div>
            <div className="theme-item theme-4-img">
              <img src={Theme4} alt={ThemeMeta.aud} title={ThemeMeta.aud} />
              <h2 className="lighter upper">{ThemeMeta.aud}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>)
}

export default Themes